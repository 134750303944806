import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const Bio = styled.div`
  flex: 1.08;
  p {
    margin-bottom: 1rem;
  }
`;

const Contact = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: 1.2;

  a {
    max-width: fit-content;
  }

  @media only screen and (max-width: 1000px) {
    align-items: flex-start;
    flex: 0;
  }
`;


const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allFile(filter: {name: {eq: "bio"}}) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            email
          }
          html
        }
      }
    }
      site {
        siteMetadata {
          author {
            name
            summary
            email
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  const author = data.site.siteMetadata?.author;
  const bio = data.allFile.nodes[0].childMarkdownRemark.html;
  const email = data.allFile.nodes[0].childMarkdownRemark.frontmatter.email;

  return (
    <Container>
      <Bio>
        {author?.name && (
          <div
            dangerouslySetInnerHTML={{ __html: bio }}
          />
        )}
      </Bio>
      <Contact>
        <a href={`mailto:${email}`} target="_blank">{email}</a>
      </Contact>
    </Container>
  );
};

export default Header;
