import React, { useState } from "react";
import { Link, graphql, PageProps } from "gatsby";
import styled from "styled-components";

import Header from "../components/Header";
import Layout from "../components/layout";
import SEO from "../components/seo";

interface Node {
  childMarkdownRemark: {
    frontmatter: {
      title: string;
      links: Post[];
    };
  };
}
interface Props extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string,
        author: {
          email: string;
        },
      };
    },
    allMarkdownRemark: {
      nodes: any;
    },
    allFile: {
      edges: {
        node: Node;
      }[];
    };
  };
}

const List = styled.table`
  td {
    padding: 0.5rem;
  }

  tr {
    border-top: 1px solid var(--color-text);
  }


  td:nth-child(2) { width: 100%; }

  thead {
    text-align: left;
    th {
      padding: 1rem;
    }
  }

  @media only screen and (min-width: 1000px) {
    td {
      /* padding: 1.5rem 2rem; */
    }

    thead {
      th {
        padding: 1.5rem 2rem;
      }
    }
  }
`;

const Date = styled.span<{ show: boolean; }>`
  visibility: ${props => props.show ? "default" : "hidden"};
`;

const Posts = styled.div`
  margin-top: 2rem;

  @media only screen and (min-width: 1000px) {
    margin-top: 3rem;
  }
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionHeader = styled.div`
  @media only screen and (min-width: 1000px) {
    border-bottom: 1px solid var(--color-text);
  }
`;

const Spacer = styled.td`
  padding: 0 !important;

  @media only screen and (min-width: 1000px) {
    padding: 0 3rem !important;
  }
`;

const Tag = styled.p`
  text-transform: capitalize;
  font-weight: bold;
  margin: 0.25rem 0.5rem;
  @media only screen and (min-width: 1000px) {
    font-weight: normal;
    margin: 0.25rem 1rem;
  }
`;

interface Post {
  title: string;
  date: string;
  tag?: string;
  publication?: string;
  url?: string;
}

const SectionComponent = ({ posts, tag }: { tag: string; posts: Post[]; }) => {
  const [showDate, setShowDate] = useState<number | undefined>();

  return (
    <Section>
      <SectionHeader>
        <Tag>{tag}</Tag>
      </SectionHeader>
      <List>
        <tbody>
          {posts.map((post: Post, i) => {
            if (!post) return;
            const title = post.title;
            const publication = post.publication;

            return (
              <tr key={i}>
                <Spacer />
                <td>
                  <a
                    href={post.url}
                    onMouseLeave={() => setShowDate(undefined)}
                    onMouseEnter={() => setShowDate(i)}
                  >
                    {title}, <i>{publication}</i>
                  </a>
                </td>
                <td>
                  {showDate === i && post.date}
                </td>
              </tr>
            );
          })}
        </tbody>
      </List>
    </Section>
  );
};

const BlogIndex = ({ data, location }: Props) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;

  const parsedPosts = data.allFile.edges.map(edge => edge.node).reduce((acc: { [category: string]: Post[]; }, next: Node) => {
    const title = next.childMarkdownRemark.frontmatter.title.toLowerCase();
    acc[title] = next.childMarkdownRemark.frontmatter.links;
    return acc;
  }, {});


  if (Object.entries(parsedPosts).length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <Header />
        <p>
          Nothing to see here...
        </p>
      </Layout>
    );
  }

  return (
    <Layout location={location} title={siteTitle}>
      {/* <SEO title="All" /> */}
      <Header />

      <Posts>
        {["audio", "video", "writing"].map(tag => parsedPosts[tag] && <SectionComponent tag={tag} posts={parsedPosts[tag]} key={tag} />)}
      </Posts>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author {
          email
        }
      }
    }
    allFile(filter: {sourceInstanceName: {eq: "entries"}}) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              title
              links {
                title
                url
                date(formatString: "YYYY")
                publication
              }
            }
          }
        }
      }
    }
  }
`;
